var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "historyQuery",
      staticClass: "history-query-page",
      class: { "ticket-change-page": _vm.isShowTicketChange },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isShowTicketChange,
              expression: "!isShowTicketChange",
            },
          ],
          staticClass: "main-content",
        },
        [
          _c(
            "div",
            { ref: "form", staticClass: "form-area" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    model: _vm.form,
                    "label-position": "left",
                    "label-width": "85px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单编号：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入订单编号" },
                        model: {
                          value: _vm.form.orderId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "orderId",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.orderId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "下单人：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入下单人" },
                        model: {
                          value: _vm.form.passengerPhone,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "passengerPhone",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.passengerPhone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系电话：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入联系人电话" },
                        model: {
                          value: _vm.form.orderPhone,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "orderPhone",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.orderPhone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单状态：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            "collapse-tags": true,
                            multiple: "",
                            clearable: "",
                            placeholder: "请选择订单状态",
                          },
                          model: {
                            value: _vm.form.orderStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "orderStatus", $$v)
                            },
                            expression: "form.orderStatus",
                          },
                        },
                        _vm._l(_vm.orderStatusList, function (role) {
                          return _c("el-option", {
                            key: role.value,
                            attrs: { label: role.label, value: role.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "下单日期起：" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          type: "datetime",
                          placeholder: "选择时间",
                        },
                        model: {
                          value: _vm.form.startDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "startDate", $$v)
                          },
                          expression: "form.startDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "下单日期止：" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          type: "datetime",
                          placeholder: "选择时间",
                        },
                        model: {
                          value: _vm.form.endDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "endDate", $$v)
                          },
                          expression: "form.endDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "线路：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "请选择线路",
                          },
                          model: {
                            value: _vm.form.lineId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "lineId", $$v)
                            },
                            expression: "form.lineId",
                          },
                        },
                        _vm._l(_vm.lineList, function (role) {
                          return _c("el-option", {
                            key: role.id,
                            attrs: { label: role.name, value: role.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "发车时间起：" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          type: "datetime",
                          placeholder: "选择时间",
                        },
                        model: {
                          value: _vm.form.departureStartDateTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "departureStartDateTime", $$v)
                          },
                          expression: "form.departureStartDateTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "发车时间止：" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          type: "datetime",
                          placeholder: "选择时间",
                        },
                        model: {
                          value: _vm.form.departureEndDateTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "departureEndDateTime", $$v)
                          },
                          expression: "form.departureEndDateTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属公司：" } },
                    [
                      _c("companySelect", {
                        attrs: {
                          clearable: true,
                          "company-tree": _vm.companyList,
                          valuename: _vm.valueAddName,
                          value: _vm.valueAdd,
                        },
                        on: { getValue: _vm.getAddGroupId },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.onSearch()
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.onExport()
                            },
                          },
                        },
                        [_vm._v("导出")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("Table", {
            attrs: {
              "table-data": _vm.tableData,
              "title-name": _vm.titleName,
              operation: true,
              "col-class-type": true,
              "table-height": _vm.tableHeight,
              "operation-width": "260",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "operation-style" },
                      [
                        scope.scopeRow.orderStatus == 10 &&
                        scope.scopeRow.purchaseMethod == 1
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { color: "#ff8627" },
                                attrs: {
                                  type: "text",
                                  size: "small",
                                  sort: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.payMoney(scope.scopeRow)
                                  },
                                },
                              },
                              [_vm._v("去支付")]
                            )
                          : _vm._e(),
                        !scope.scopeRow.refundStatus &&
                        scope.scopeRow.orderStatus < 70 &&
                        scope.scopeRow.orderStatus != 10 &&
                        scope.scopeRow.purchaseMethod != 3 &&
                        _vm.listFind("退票")
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    size: "small",
                                    sort: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancelTicket(scope.scopeRow)
                                    },
                                  },
                                },
                                [_vm._v("退票")]
                              ),
                            ]
                          : _vm._e(),
                        _vm.listFind("改签") &&
                        (scope.scopeRow.orderStatus == 20 ||
                          scope.scopeRow.orderStatus == 30 ||
                          scope.scopeRow.orderStatus == 40) &&
                        scope.scopeRow.purchaseMethod != 3
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "small",
                                  sort: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.ticketChangeFun(scope.scopeRow)
                                  },
                                },
                              },
                              [_vm._v("改签")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openPopupFun(scope.scopeRow)
                              },
                            },
                          },
                          [_vm._v("订单详情")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onHistoryDetail(scope.scopeRow)
                              },
                            },
                          },
                          [_vm._v("调度详情")]
                        ),
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "div",
            { ref: "pagination", staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  "page-size": _vm.form.pageSize,
                  "current-page": _vm.form.currentPage,
                },
                on: {
                  "size-change": _vm.onSizeChange,
                  "current-change": _vm.onCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增回访",
            visible: _vm.visitFormVisible,
            width: "70vw",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visitFormVisible = $event
            },
          },
        },
        [
          _c("add-visit-form", {
            ref: "visitForm",
            on: {
              saveOk: function ($event) {
                _vm.visitFormVisible = false
              },
              cancel: function ($event) {
                _vm.visitFormVisible = false
              },
            },
          }),
        ],
        1
      ),
      _vm.dialogVisible3
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "调度历史详情",
                visible: _vm.dialogVisible3,
                width: "60%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible3 = $event
                },
              },
            },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.historyData, stripe: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "orderId",
                      label: "订单号",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "scheduleType",
                      label: "调度类型",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  scope.row.scheduleType == 1
                                    ? "派车"
                                    : "派司机"
                                )
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      441750384
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "userName",
                      label: "调度人",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "scheduleContent",
                      label: "调度内容",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "调度时间",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible3 = false
                        },
                      },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.tablePopupShow
        ? _c(
            "div",
            { staticClass: "table-Popup" },
            [
              _c("table-popup", {
                attrs: {
                  "table-data2": _vm.tableData2,
                  "title-name2": _vm.titleName2,
                  "data-popup": _vm.dataPopup,
                  "order-status-list": _vm.orderStatusList,
                },
                on: { closeFun: _vm.closeFun },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isShowTicketChange
        ? _c(
            "div",
            { staticClass: "ticket-change" },
            [
              _c("div", { staticClass: "exit", on: { click: _vm.returnFun } }, [
                _c("i", { staticClass: "iconfont its_ba_fanhui" }),
              ]),
              _c(
                "el-steps",
                {
                  attrs: {
                    active: _vm.ticketChangeActive,
                    "align-center": "",
                    "finish-status": "success",
                  },
                },
                [
                  _c("el-step", {
                    attrs: {
                      title: "确认改签信息",
                      description: "请确认乘客信息是否有误",
                    },
                  }),
                  _c("el-step", {
                    attrs: {
                      title:
                        _vm.ticketChangeActive == 0 ? "改签班次" : "确认新班次",
                      description: "修改新的班次",
                    },
                  }),
                  _c("el-step", { attrs: { title: "完成", description: "" } }),
                ],
                1
              ),
              _vm.ticketChangeActive !== 2
                ? _c("phoneOrderDetial", {
                    ref: "phoneOrderDetialRef",
                    attrs: {
                      from: "ticketChange",
                      step: _vm.ticketChangeActive,
                      ticketChangeForm: _vm.ticketChangeForm,
                    },
                    on: {
                      placeOrder: _vm.placeOrder,
                      watchOrderAmount: _vm.watchOrderAmount,
                    },
                  })
                : _c("div", { staticClass: "result-box" }, [
                    _vm.isTicketChange
                      ? _c("div", { staticClass: "success-text" }, [
                          _c("img", {
                            attrs: {
                              src: require("../../../assets/images/home/success.svg"),
                            },
                          }),
                          _c("span", [_vm._v("改签成功")]),
                        ])
                      : _c("div", { staticClass: "error-text" }, [
                          _c("img", {
                            attrs: {
                              src: require("../../../assets/images/home/error.svg"),
                            },
                          }),
                          _c("span", [_vm._v("改签失败")]),
                        ]),
                  ]),
              _c("div", { staticClass: "button-box" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.ticketChangeActive == 0,
                        expression: "ticketChangeActive == 0",
                      },
                    ],
                  },
                  [
                    _c("el-button", { on: { click: _vm.returnFun } }, [
                      _vm._v("取消并返回"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.nextFun },
                      },
                      [_vm._v("信息确认无误")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.ticketChangeActive == 1,
                        expression: "ticketChangeActive == 1",
                      },
                    ],
                  },
                  [
                    _c("el-button", { on: { click: _vm.previousFun } }, [
                      _vm._v("上一步"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          disabled: _vm.isDisabledConfirmTicketFlag,
                        },
                        on: { click: _vm.confirmTicketChangeFun },
                      },
                      [_vm._v("确认改签")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.ticketChangeActive == 2,
                        expression: "ticketChangeActive == 2",
                      },
                    ],
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.returnFun("isRequest")
                          },
                        },
                      },
                      [_vm._v("返回上一页")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.charterPopupShow
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.charterPopupShow,
                width: "800px",
                title: "退票",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.charterPopupShow = $event
                },
                close: _vm.charterPopupClosePopupFun,
              },
            },
            [
              _c(
                "div",
                { staticStyle: { margin: "0 40px" } },
                [
                  _c("Table", {
                    attrs: {
                      "table-data": _vm.tableData2,
                      "title-name": _vm.titleName3,
                      "type-checkbox": true,
                    },
                    on: { closeFun: _vm.closeFun, "select-all": _vm.select },
                  }),
                  _c("div", { staticClass: "feeBox" }, [
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("退票手续费(%)")]),
                        _c(
                          "el-select",
                          {
                            staticClass: "charterSelect",
                            on: { change: _vm.refundChange },
                            model: {
                              value: _vm.serviceCharge,
                              callback: function ($$v) {
                                _vm.serviceCharge = $$v
                              },
                              expression: "serviceCharge",
                            },
                          },
                          _vm._l(_vm.serviceChargeList, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("退票金额")]),
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.refundTotal,
                            callback: function ($$v) {
                              _vm.refundTotal = $$v
                            },
                            expression: "refundTotal",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "reasonBox" },
                    [
                      _c("p", [_vm._v("原因")]),
                      _c("el-input", {
                        staticClass: "textareaInputs",
                        attrs: {
                          type: "textarea",
                          placeholder:
                            "填写原因时请注意，该信息将以短信的形式发送给用户。",
                        },
                        model: {
                          value: _vm.ReasonForRefund,
                          callback: function ($$v) {
                            _vm.ReasonForRefund =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "ReasonForRefund",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("p", { staticClass: "desc" }, [
                    _vm._v(
                      " 点击确认按钮提交退款申请后， 需等待当前弹窗消失， 期间请勿进行其它操作 "
                    ),
                  ]),
                  _c(
                    "el-button",
                    { on: { click: _vm.charterPopupClosePopupFun } },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.loading },
                      on: { click: _vm.charterPopupConfirmFun },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.showCreateOrder
        ? _c("reservedFormPopup", {
            ref: "reserveForm",
            attrs: {
              "header-text": "订票",
              isAwaitPay: true,
              createOrderArgument: _vm.shiftIntercityObj,
            },
            on: {
              closePopupFun: _vm.closePopAndUpDate,
              saveOk: _vm.closePopAndUpDate,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }