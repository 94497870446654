<template>
  <!-- 售票/派车查询9.0--使用的是历史调度页面 -->
  <div
    class="history-query-page"
    :class="{ 'ticket-change-page': isShowTicketChange }"
    ref="historyQuery"
  >
    <div class="main-content" v-show="!isShowTicketChange">
      <div ref="form" class="form-area">
        <el-form
          :inline="true"
          :model="form"
          class="demo-form-inline"
          label-position="left"
          label-width="85px"
        >
          <el-form-item label="订单编号：">
            <el-input
              v-model.trim="form.orderId"
              placeholder="请输入订单编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="下单人：">
            <el-input
              v-model.trim="form.passengerPhone"
              placeholder="请输入下单人"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话：">
            <el-input
              v-model.trim="form.orderPhone"
              placeholder="请输入联系人电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="订单状态：">
            <el-select
              v-model="form.orderStatus"
              :collapse-tags="true"
              multiple
              clearable
              placeholder="请选择订单状态"
            >
              <el-option
                v-for="role in orderStatusList"
                :key="role.value"
                :label="role.label"
                :value="role.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="下单日期起：">
            <el-date-picker
              v-model="form.startDate"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="下单日期止：">
            <el-date-picker
              v-model="form.endDate"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="线路：">
            <el-select
              v-model="form.lineId"
              filterable
              clearable
              placeholder="请选择线路"
            >
              <el-option
                v-for="role in lineList"
                :key="role.id"
                :label="role.name"
                :value="role.id"
              ></el-option>
            </el-select>
          </el-form-item>          
          <el-form-item label="发车时间起：">
            <el-date-picker
              v-model="form.departureStartDateTime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="发车时间止：">
            <el-date-picker
              v-model="form.departureEndDateTime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="所属公司：">
            <companySelect
              :clearable="true"
              :company-tree="companyList"
              :valuename="valueAddName"
              :value="valueAdd"
              @getValue="getAddGroupId"
            ></companySelect>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="onSearch()"
              >查询</el-button
            >
            <el-button type="primary" size="small" @click="onExport()"
              >导出</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <Table
        :table-data="tableData"
        :title-name="titleName"
        :operation="true"
        :col-class-type="true"
        :table-height="tableHeight"
        operation-width="260"
      >
        <template slot-scope="scope">
          <div class="operation-style">
            <el-button
              v-if="
                scope.scopeRow.orderStatus == 10 &&
                scope.scopeRow.purchaseMethod == 1
              "
              type="text"
              size="small"
              sort="primary"
              style="color: #ff8627"
              @click="payMoney(scope.scopeRow)"
              >去支付</el-button
            >
            <template
              v-if="
                !scope.scopeRow.refundStatus &&
                scope.scopeRow.orderStatus < 70 &&
                scope.scopeRow.orderStatus != 10 &&
                scope.scopeRow.purchaseMethod != 3 &&
                listFind('退票')
              "
            >
              <el-button
                type="text"
                size="small"
                sort="primary"
                @click="cancelTicket(scope.scopeRow)"
                >退票</el-button
              >
            </template>
            <!-- v7.1 -->
            <el-button
              v-if="
                listFind('改签') &&
                (scope.scopeRow.orderStatus == 20 ||
                  scope.scopeRow.orderStatus == 30 ||
                  scope.scopeRow.orderStatus == 40) &&
                scope.scopeRow.purchaseMethod != 3
              "
              type="text"
              size="small"
              sort="primary"
              @click="ticketChangeFun(scope.scopeRow)"
              >改签</el-button
            >
            <el-button
              type="text"
              size="small"
              sort="primary"
              @click="openPopupFun(scope.scopeRow)"
              >订单详情</el-button
            >

            <el-button
              type="text"
              size="small"
              sort="primary"
              @click="onHistoryDetail(scope.scopeRow)"
              >调度详情</el-button
            >
          </div>
        </template>
      </Table>
      <div ref="pagination" class="pagination">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-size="form.pageSize"
          :current-page="form.currentPage"
          @size-change="onSizeChange"
          @current-change="onCurrentChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 7.2 新增回访填写表单 -->
    <el-dialog title="新增回访" :visible.sync="visitFormVisible" width="70vw">
      <add-visit-form
        ref="visitForm"
        @saveOk="visitFormVisible = false"
        @cancel="visitFormVisible = false"
      />
    </el-dialog>
    <el-dialog
      v-if="dialogVisible3"
      title="调度历史详情"
      :visible.sync="dialogVisible3"
      width="60%"
    >
      <el-table :data="historyData" stripe>
        <el-table-column
          prop="orderId"
          label="订单号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="scheduleType"
          label="调度类型"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{
            scope.row.scheduleType == 1 ? "派车" : "派司机"
          }}</template>
        </el-table-column>
        <el-table-column
          prop="userName"
          label="调度人"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="scheduleContent"
          label="调度内容"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="调度时间"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible3 = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
    <div v-if="tablePopupShow" class="table-Popup">
      <table-popup
        :table-data2="tableData2"
        :title-name2="titleName2"
        :data-popup="dataPopup"
        :order-status-list="orderStatusList"
        @closeFun="closeFun"
      />
    </div>
    <!-- v7.1改签页面 -->
    <div v-if="isShowTicketChange" class="ticket-change">
      <div class="exit" @click="returnFun">
        <i class="iconfont its_ba_fanhui"></i>
      </div>
      <!-- <div class="title">改签</div> -->
      <el-steps
        :active="ticketChangeActive"
        align-center
        finish-status="success"
      >
        <el-step
          title="确认改签信息"
          description="请确认乘客信息是否有误"
        ></el-step>
        <el-step
          :title="ticketChangeActive == 0 ? '改签班次' : '确认新班次'"
          description="修改新的班次"
        ></el-step>
        <el-step title="完成" description></el-step>
      </el-steps>
      <phoneOrderDetial
        ref="phoneOrderDetialRef"
        v-if="ticketChangeActive !== 2"
        from="ticketChange"
        :step="ticketChangeActive"
        :ticketChangeForm="ticketChangeForm"
        @placeOrder="placeOrder"
        @watchOrderAmount="watchOrderAmount"
      ></phoneOrderDetial>
      <div v-else class="result-box">
        <div v-if="isTicketChange" class="success-text">
          <img src="../../../assets/images/home/success.svg" />
          <span>改签成功</span>
        </div>
        <div v-else class="error-text">
          <img src="../../../assets/images/home/error.svg" />
          <span>改签失败</span>
        </div>
      </div>
      <div class="button-box">
        <div v-show="ticketChangeActive == 0">
          <el-button @click="returnFun">取消并返回</el-button>
          <el-button type="primary" @click="nextFun">信息确认无误</el-button>
        </div>
        <div v-show="ticketChangeActive == 1">
          <el-button @click="previousFun">上一步</el-button>
          <el-button
            type="primary"
            :disabled="isDisabledConfirmTicketFlag"
            @click="confirmTicketChangeFun"
            >确认改签</el-button
          >
        </div>
        <div v-show="ticketChangeActive == 2">
          <el-button type="primary" @click="returnFun('isRequest')"
            >返回上一页</el-button
          >
        </div>
      </div>
    </div>
    <el-dialog
      v-if="charterPopupShow"
      :visible.sync="charterPopupShow"
      width="800px"
      title="退票"
      @close="charterPopupClosePopupFun"
    >
      <div style="margin: 0 40px">
        <Table
          :table-data="tableData2"
          :title-name="titleName3"
          :type-checkbox="true"
          @closeFun="closeFun"
          @select-all="select"
        />
        <div class="feeBox">
          <div>
            <p>退票手续费(%)</p>
            <el-select
              v-model="serviceCharge"
              class="charterSelect"
              @change="refundChange"
            >
              <el-option
                v-for="item in serviceChargeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div>
            <p>退票金额</p>
            <el-input v-model="refundTotal" disabled />
          </div>
        </div>
        <div class="reasonBox">
          <p>原因</p>
          <el-input
            v-model.trim="ReasonForRefund"
            class="textareaInputs"
            type="textarea"
            placeholder="填写原因时请注意，该信息将以短信的形式发送给用户。"
          ></el-input>
        </div>
      </div>
      <div slot="footer" class="footer">
        <p class="desc">
          点击确认按钮提交退款申请后， 需等待当前弹窗消失， 期间请勿进行其它操作
        </p>
        <el-button @click="charterPopupClosePopupFun">取消</el-button>
        <el-button
          type="primary"
          :disabled="loading"
          @click="charterPopupConfirmFun"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <!-- 待支付 -->
    <reservedFormPopup
      v-if="showCreateOrder"
      ref="reserveForm"
      header-text="订票"
      :isAwaitPay="true"
      :createOrderArgument="shiftIntercityObj"
      @closePopupFun="closePopAndUpDate"
      @saveOk="closePopAndUpDate"
    />
  </div>
</template>
<script>
import {
  historyExportAPI,
  queryScheduleHistoryList,
  queryScheduleHistoryDetail,
  getCompanyTree,
  getAllLine,
  referOrderDetialAPI,
  getOrderInfoAPI,
  cancelTicket,
} from "@/api/lib/api.js";
import TablePopup from "@/components/charterPopup/tablePopup.vue";
import addVisitForm from "./addVisitForm/addVisitForm.vue";
import companySelect from "@/components/treeSelect/companySelect.vue";
import phoneOrderDetial from "./phoneOrderDetial.vue";
import reservedFormPopup from "./components/reservedFormPopup.vue";

export default {
  components: {
    companySelect,
    addVisitForm,
    TablePopup,
    phoneOrderDetial,
    reservedFormPopup,
  },
  data() {
    return {
      form: {
        orderId: null,
        passengerPhone: null,
        orderPone: null,
        orderStatus: null,
        lineId: null,
        startDate: null,
        endDate: null,
        currentPage: 1,
        pageSize: 10,
        departureStartDateTime: "",
        departureEndDateTime: "",
      },
      total: 0,
      lineList: [],
      orderStatusList: [
        { value: 10, label: "待支付" },
        { value: 20, label: "待配车" },
        { value: 30, label: "已配车（待派单）" },
        { value: 40, label: "已派单（待接驾）" },
        { value: 50, label: "司机申请取消接驾" },
        { value: 60, label: "已接驾" },
        { value: 70, label: "已完成" },
        { value: 80, label: "取消接驾" },
        { value: 110, label: "订单取消" },
        { value: 120, label: "已退票" },
        { value: 130, label: "已改签" },
      ],
      titleName: [
        {
          title: "订单编号",
          props: "orderId",
          width: "200",
        },
        {
          title: "下单人",
          props: "passengerPhone",
        },
        {
          title: "联系电话",
          width: 120,
          props: "orderPhone",
        },
        {
          title: "乘客数量",
          props: "passengerCount",
        },
        {
          title: "有效乘车人数",
          width: 120,
          props: "validPassengersNum",
        },
        {
          title: "携童数",
          props: "carryingChildrenNum",
        },
        {
          title: "票价",
          props: "ticketPricesPer",
        },
        {
          title: "服务费",
          props: "servicePricesPer",
        },
        {
          title: "应付金额",
          props: "payablePrices",
        },
        {
          title: "优惠金额",
          props: "preferentialPrices",
        },
        {
          title: "实付金额",
          props: "totalPrices",
        },
        {
          title: "购票方式",
          props: "purchaseMethod",
          SpecialJudgment: (res) => {
            return res == 0
              ? "乘客购票"
              : res == 1
              ? "后台购票"
              : res == 2
              ? "快客码购票"
              : res == 3
              ? "Api购票"
              : "预留";
          },
        },
        {
          title: "所属公司",
          props: "companyName",
          width: 120,
        },
        {
          title: "线路",
          width: 100,
          SpecialJudgment: (res) => {
            return res.startStation + `-` + res.endStation;
          },
        },
        {
          title: "班次时间",
          width: 140,
          SpecialJudgment: (res) => {
            let str = res.departureDate;
            if (res.shiftType === 0) {
              return str + ` ` + res.startDepartureTime;
            }
            return (
              str + ` ` + res.startDepartureTime + "-" + res.endDepartureTime
            );
          },
        },
        {
          title: "上车点",
          props: "startAddress",
        },
        {
          title: "下车点",
          props: "endAddress",
        },
        {
          title: "下单时间",
          width: 170,
          props: "createTime",
        },
        {
          title: "订单状态",
          props: "orderStatus",
          SpecialJudgment: (res) => {
            return this.orderStatusTextFun(
              this.orderStatusList.find((t) => t.value == res).label
            );
          },
        },
        {
          title: "退(改)优惠金额",
          props: "refundTicketPreferentialPrices",
          width: 130,
        },
        {
          title: "退票金额",
          props: "refundFee",
        },
        {
          title: "退票手续费",
          width: "100",
          props: "poundage",
        },
        {
          title: "最后退票时间",
          props: "refundTime",
          width: 110,
        },
        {
          title: "改签退回金额",
          props: "changedRefundPrices",
          width: 120,
        },
        {
          title: "改签手续费",
          props: "changedServicePrices",
          width: 100,
        },
        {
          title: "最后改签时间",
          props: "changedTime",
          width: 120,
        },
      ],
      tableData: [],
      companyList: [],
      tableHeight: 0,
      historyData: [],
      dialogVisible3: false,
      visitFormVisible: false,
      valueAddName: null,
      valueAdd: null,
      tablePopupShow: false,
      dataPopup: {},
      tableData2: [],
      titleName2: [
        {
          title: "票号",
          props: "id",
          width: "200",
        },
        {
          title: "乘客类型",
          props: "segment",
          SpecialJudgment: (res) => {
            return res == 0 ? "儿童" : "成人";
          },
        },
        {
          title: "乘客姓名",
          props: "passengerName",
        },
        {
          title: "手机号码",
          width: 120,
          props: "passengerPhone",
        },
        {
          title: "身份证号码",
          props: "idCardNo",
          width: 120,
          SpecialJudgment: (res) => {
            return res == "匿名用户"
              ? res
              : this.decryptDes(res).replace(
                  /^(.{4})(?:\d+)(.{3})$/,
                  "$1******$2"
                );
          },
        },
        {
          title: "是否携童",
          props: "carryingChildren",
          SpecialJudgment: (res) => (res ? "是" : "否"),
        },
        {
          title: "票价",
          props: "ticketPricesPer",
        },
        {
          title: "服务费",
          props: "servicePricesPer",
        },
        {
          title: "应付金额",
          props: "payablePrices",
        },
        {
          title: "优惠金额",
          props: "preferentialPrices",
        },
        {
          title: "实付金额",
          props: "totalPrices",
        },
        {
          title: "订单状态",
          props: "orderStatus",
          SpecialJudgment: (res) => {
            return this.childOrderStatusList.find((t) => t.value == res).label;
          },
        },
        {
          title: "退(改)优惠金额",
          props: "refundTicketPreferentialPrices",
          width: 130,
        },
        {
          title: "退票金额",
          props: "refundTicketPrices",
        },
        {
          title: "退票手续费",
          props: "refundTicketServicePrices",
          width: 120,
        },
        {
          title: "退票时间",
          props: "refundTime",
        },
        {
          title: "改签退回金额",
          props: "changedRefundPrices",
          width: 120,
        },
        {
          title: "改签手续费",
          props: "changedServicePrices",
          width: 120,
        },
        {
          title: "改签时间",
          props: "changedTime",
        },
      ],
      titleName3: [
        {
          title: "票号",
          props: "id",
          width: "200",
        },
        {
          title: "乘客类型",
          props: "segment",
          SpecialJudgment: (res) => {
            return res == 0 ? "儿童" : "成人";
          },
        },
        {
          title: "乘客姓名",
          props: "passengerName",
        },
        {
          title: "身份证号码",
          width: 120,
          props: "idCardNo",
          SpecialJudgment: (res) => {
            return res == "匿名用户"
              ? res
              : this.decryptDes(res).replace(
                  /^(.{4})(?:\d+)(.{3})$/,
                  "$1******$2"
                );
          },
        },
        {
          title: "是否携童",
          props: "carryingChildren",
          SpecialJudgment: (res) => (res ? "是" : "否"),
        },
        {
          title: "票价",
          props: "ticketPricesPer",
        },
        {
          title: "服务费",
          props: "servicePricesPer",
        },
        {
          title: "支付金额",
          props: "totalPrices",
        },
        {
          title: "订单状态",
          props: "orderStatus",
          SpecialJudgment: (res) => {
            return this.childOrderStatusList.find((t) => t.value == res).label;
          },
        },
      ],
      childOrderStatusList: [
        { value: 20, label: "已支付" },
        { value: 90, label: "用户已退票" },
        { value: 95, label: "退款中" },
        { value: 100, label: "后台已退票" },
        { value: 130, label: "已改签" },
        { value: null, label: "" },
      ],
      isShowTicketChange: false, //是否显示改签页面
      ticketChangeActive: 0, //改签步骤条
      ticketChangeForm: {},
      isTicketChange: false, //改签结果
      isDisabledConfirmTicketFlag: true,
      charterPopupShow: false,
      ReasonForRefund: "",
      refundTotal: 0,
      ticketPrice: 0,
      serviceCharge: 0,
      cancelTicketObj: {},
      children: 0,
      loading: false,
      serviceChargeList: [{ label: "0", value: 0 }],
      showCreateOrder: false,
      shiftIntercityObj: null,
    };
  },
  created() {
    // 获取各种下拉列表的数据
    this.getCompanyList();
    this.getLineList();
  },
  mounted() {
    this.$nextTick(() => {
      this.getHistoryList();
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
    this.form.startDate = this.getLastMonthTime(new Date()) + " 00:00:00";
    let day = new Date();
    this.form.endDate =
      day.getFullYear() +
      "-" +
      (day.getMonth() + 1) +
      "-" +
      day.getDate() +
      " 23:59:59";
  },
  methods: {
    // 获取上一个月时间,返回yyyy-MM-dd字符串
    getLastMonthTime(date) {
      //  1    2    3    4    5    6    7    8    9   10    11   12月
      var daysInMonth = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      var strYear = date.getFullYear();
      var strDay = date.getDate();
      var strMonth = date.getMonth() + 1;
      //一、解决闰年平年的二月份天数   //平年28天、闰年29天//能被4整除且不能被100整除的为闰年,或能被100整除且能被400整除
      if ((strYear % 4 === 0 && strYear % 100 !== 0) || strYear % 400 === 0) {
        daysInMonth[2] = 29;
      }
      if (strMonth - 1 === 0) {
        //二、解决跨年问题
        strYear -= 1;
        strMonth = 12;
      } else {
        strMonth -= 1;
      }
      //  strDay = daysInMonth[strMonth] >= strDay ? strDay : daysInMonth[strMonth];
      strDay = Math.min(strDay, daysInMonth[strMonth]); //三、前一个月日期不一定和今天同一号，例如3.31的前一个月日期是2.28；9.30前一个月日期是8.30

      if (strMonth < 10) {
        //给个位数的月、日补零
        strMonth = "0" + strMonth;
      }
      if (strDay < 10) {
        strDay = "0" + strDay;
      }
      var datastr = strYear + "-" + strMonth + "-" + strDay;
      return datastr;
    },
    decryptDes(message, key = "inheritech_key") {
      var cryptoJs = require("crypto-js");
      const keyHex = cryptoJs.enc.Utf8.parse(key);
      const decrypted = cryptoJs.DES.decrypt(
        message, // 若message是base64格式，则无需转16进制hex，直接传入message即可
        keyHex,
        {
          mode: cryptoJs.mode.ECB,
          padding: cryptoJs.pad.Pkcs7,
        }
      );
      return decrypted.toString(cryptoJs.enc.Utf8);
    },
    closePopAndUpDate() {
      this.showCreateOrder = false;
      this.getHistoryList();
    },
    // 去支付
    payMoney(item) {
      this.shiftIntercityObj = { ...item };
      this.showCreateOrder = true;
    },
    recurSion(num) {
      if (num < 90) {
        let Numb = (num += 5);
        this.serviceChargeList.push({
          label: Numb,
          value: Numb,
        });
        this.recurSion(Numb);
      } else {
        return;
      }
    },
    select(selection) {
      this.ticketPrice = 0;
      this.cancelTicketObj.ids = [];
      this.children = 0;
      if (selection.length) {
        selection.forEach((e) => {
          this.ticketPrice +=
            e.preferentialType === 0 ? e.ticketPricesPer : e.totalPrices;
          this.cancelTicketObj.ids.push(e.id);
          this.children += e.carryingChildren;
        });
        this.refundTotal = this.serviceCharge
          ? ((this.ticketPrice * (100 - this.serviceCharge)) / 100).toFixed(2)
          : this.ticketPrice.toFixed(2);
      } else {
        this.ticketPrice = 0;
        this.refundTotal = 0;
      }
    },
    refundChange(val) {
      this.refundTotal = ((this.ticketPrice * (100 - val)) / 100).toFixed(2);
    },
    charterPopupClosePopupFun() {
      this.refundTotal = 0;
      this.ticketPrice = 0;
      this.serviceCharge = 0;
      this.charterPopupShow = false;
      this.cancelTicketObj = {};
      this.loading = false;
    },
    charterPopupConfirmFun() {
      if (!this.refundTotal) {
        this.$message({
          type: "error",
          showClose: true,
          message: "请选择退票订单！",
        });
        return;
      }
      this.loading = true;
      if (this.children) {
        this.$confirm(`包含${this.children}张携童票，确认退订`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          center: true,
        }).then(() => {
          this.confirmOpera();
        });
      } else {
        this.confirmOpera();
      }
    },
    confirmOpera() {
      cancelTicket({
        orderIds: this.cancelTicketObj.ids,
        refundProportion: this.serviceCharge,
        reason: this.ReasonForRefund,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.$message({
            type: "success",
            showClose: true,
            message: "退票成功!",
          });
          this.loading = false;
          this.charterPopupShow = false;
          this.ReasonForRefund = "";
          this.getHistoryList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 退票
    cancelTicket(row, name) {
      const id = row.orderId || row.intercityOrderId;
      referOrderDetialAPI(id).then((res) => {
        if (res.code === "SUCCESS") {
          if (row.totalPrices == 0) {
            this.$confirm("确认退票？", "提示", {
              center: true,
            }).then(() => {
              const obj = {
                orderIds: res.data.map((i) => i.id),
                refundProportion: 0,
                reason: "取消订单",
              };
              cancelTicket(obj).then((res) => {
                if (res.code == "SUCCESS") {
                  this.$message.success("退票成功");
                  this.getHistoryList();
                }
              });
            });
          } else {
            this.serviceChargeList =[{ label: "0", value: 0 }];
            this.recurSion(0);
            this.tableData2 = res.data;
            this.charterPopupShow = true;
          }
        }
      });
    },
    //监听订单金额的变化
    watchOrderAmount(val) {
      this.isDisabledConfirmTicketFlag = val;
    },
    //步骤条下一步
    nextFun() {
      this.ticketChangeActive = 1;
    },
    //步骤条上一步
    previousFun() {
      this.$refs.phoneOrderDetialRef.$refs.ruleForm.resetFields();
      this.$refs.phoneOrderDetialRef.getTicketChangeFormFun();
      this.ticketChangeActive = 0;
    },
    //确认改签
    confirmTicketChangeFun() {
      this.$refs.phoneOrderDetialRef.placeOrder();
    },
    placeOrder(val) {
      if (val == "SUCCESS") {
        this.isTicketChange = true;
      } else {
        this.isTicketChange = false;
      }
      this.ticketChangeActive = 2;
    },
    //返回按钮
    returnFun(flag) {
      this.$route.meta.title = "售票派单查询";
      this.ticketChangeActive = 0;
      this.isShowTicketChange = false;
      if (flag === "isRequest") {
        this.getHistoryList();
      }
    },
    //v7.1改签
    ticketChangeFun(row) {
      //orderPayStatus 支付状态 0：未支付 1：支付
      // if (row.purchaseMethod == 4 && row.totalPrices == 0) {
      if (row.orderPayStatus === 0) {
        this.$router.push({
          name: "telebookingNew",
          params: { id: row.orderId },
        });
        return;
      }
      this.$route.meta.title = "改签";
      getOrderInfoAPI({ orderId: row.orderId }).then((res) => {
        if (res.code === "SUCCESS") {
          this.ticketChangeForm = res.data;
          this.isShowTicketChange = true;
        }
      });
    },
    closeFun() {
      this.tablePopupShow = false;
    },
    // 获取企业树
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    // 企业回显
    getAddGroupId(v) {
      if (v !== null) {
        this.form.companyIds = [v];
      } else {
        this.form.companyIds = [];
      }
    },
    // 获取线路
    getLineList() {
      getAllLine().then((res) => {
        if (res.code === "SUCCESS") {
          this.lineList = res.data;
        }
      });
    },
    openPopupFun(item) {
      this.dataPopup = item;
      referOrderDetialAPI(item.orderId).then((res) => {
        if (res.code === "SUCCESS") {
          this.tablePopupShow = true;
          this.tableData2 = res.data;
        }
      });
    },
    orderStatusTextFun(text) {
      if (text == "后台已退票") {
        return "已退票";
      } else {
        return text;
      }
    },
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.historyQuery.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      const paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight = wholeHeight - 16 - formHeight - paginationHeight - 16;
    },
    // 点击查询
    onSearch() {
      this.form.currentPage = 1;
      this.getHistoryList();
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page;
      this.getHistoryList();
    },
    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size;
      this.form.currentPage = 1;
      this.getHistoryList();
      this.computeHeight();
    },
    // 点击新增回访
    addVisitInfo(row) {
      this.visitFormVisible = true;
      this.$nextTick(() => {
        this.$refs.visitForm?.loadInfo(row);
      });
    },
    onHistoryDetail(row) {
      queryScheduleHistoryDetail({ orderId: row.orderId }).then((res) => {
        if (res.code === "SUCCESS") {
          if (res.data.length === 0) {
            this.$message.warning("暂无调度数据！");
          } else {
            this.historyData = res.data;
            this.dialogVisible3 = true;
          }
        }
      });
    },
    //历史调度
    getHistoryList() {
      queryScheduleHistoryList(this.form).then((res) => {
        if (res.code === "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.computeHeight();
        } else {
          this.$message.error(res.msg);
          this.tableData = [];
        }
      });
    },
    onExport() {
      historyExportAPI(this.form).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName =
          "城际专车历史调度订单 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");
        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击
        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>
<style lang="scss" scoped>
.operation-style {
  text-align: left;
}
/deep/.el-select {
  input {
    height: 32px !important;
  }
}
.textareaInputs {
  width: 100%;

  /deep/ textarea {
    width: 100% !important;
    height: 60px !important;
    resize: none !important;
  }
}
/deep/.el-dropdown-link {
  cursor: pointer;
  color: #336ffe;
}

/deep/.el-icon-arrow-down {
  font-size: 12px;
}
.history-query-page {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
}
.feeBox {
  display: flex;
  margin: 40px 0;
  justify-content: space-between;

  p {
    padding-bottom: 10px;
    font-size: 14px;
  }

  /deep/.el-input__inner {
    width: 300px;
  }
}

.reasonBox {
  margin: 40px 0;

  p {
    padding-bottom: 10px;
    font-size: 14px;
  }

  /deep/.el-input__inner {
    width: 300px;
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  .desc {
    color: red;
    margin-right: 30px;
    font-size: 15px;
  }
}
.ticket-change-page {
  margin-top: 50px;
  position: relative;
  height: calc(100% - 32px - 50px) !important;

  .ticket-change {
    width: 100%;
    height: calc(100% - 32px);
    overflow: scroll;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;

    .exit {
      width: 32px;
      height: 32px;
      color: #333333;
      font-size: 15px;
      background-color: white;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);
      position: fixed;
      top: 80px;

      i {
        cursor: pointer;
      }
    }

    .title {
      width: 100%;
      height: 56px;
      line-height: 56px;
      padding-left: 10px;
      margin-bottom: 50px;
      border-bottom: 1px solid #dcdedf;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      box-sizing: border-box;
    }

    .button-box {
      border-top: 1px solid #dcdedf;
      padding-top: 16px;
      display: flex;
      justify-content: center;
    }

    .result-box {
      width: 100%;
      height: 560px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;

      div {
        display: flex;
        align-items: center;

        span {
          margin-left: 12px;
        }
      }

      .success-text {
        color: #2da641;
      }

      .error-text {
        color: #d40000;
      }
    }

    ::v-deep .is-text {
      border: none;
      color: #ffffff;
    }

    ::v-deep .el-step__main {
      .is-wait {
        color: #969799;
      }

      .el-step__description {
        color: #969799;
      }

      .el-step__title {
        color: #303133;
      }
    }

    ::v-deep .is-process {
      .is-text {
        background: #336ffe;
      }
    }

    ::v-deep .is-wait {
      .is-text {
        background: #c8c9cc;
      }
    }

    ::v-deep .is-success {
      .is-text {
        border: 2px solid #336ffe;
        color: #336ffe;
      }

      .el-step__line {
        border-color: #336ffe;
        background-color: #336ffe;
      }
    }
  }
}
</style>
